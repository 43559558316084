import React from 'react';
import './CatalogSection.css';
import { SimpleSection } from '../Notes';
import CatalogFrequency from '../CatalogFrequency';
import { ToneInterface } from '../ToneData';

type Props = {
    section: SimpleSection;
    visible: boolean;
    onAddTone: (tone: ToneInterface) => void;
};

function CatalogSection(props: Props) {
    const { section, visible, onAddTone } = props;

    return (
        <div className="catalog-section" data-content={section.key} data-visible={visible}>
            {Object.entries(section.data).map(([name, hertz]) => (
                <CatalogFrequency key={hertz} name={name} hertz={hertz} onAddTone={onAddTone} />
            ))}
        </div>
    );
}

export default CatalogSection;

import React from 'react';
import './PlayPauseButton.css';

type Props = {
    onPlayPauseClick: () => void,
    isPlaying: boolean,
}

function PlayPauseButton(props: Props) {
    const { onPlayPauseClick, isPlaying } = props;
    return (
        <button className="play-pause-button" onClick={onPlayPauseClick} data-state={isPlaying ? 'playing' : 'paused'}>{isPlaying ? 'Stop' : 'Generate'}</button>
    );
}

export default PlayPauseButton;

import React, { useCallback, useState } from 'react';
import './App.css';
import { ToneInterface, ToneTypes } from './ToneData';
import Header from './Header'
import CustomInputForm from './CustomInputForm';
import Catalog from './Catalog';
import ToneList from './ToneList';
import { startAudioOutput, stopAudioOutput } from './Audio';

const ctx = new AudioContext();
const dest = ctx.destination;

function App() {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isCatalogOpen, setCatalogOpen] = useState(false);
    const [tones, setTones] = useState([] as ToneInterface[]);
    const [waveForm, setWaveForm] = useState(ToneTypes[0]); // eslint-disable-line

    const onWaveFormChange = useCallback((type: OscillatorType) => {
        setWaveForm((wf) => `${type}`);
    }, [setWaveForm])

    const onAddTone = useCallback((tone: ToneInterface) => {
        setWaveForm((wf) => {
            if (!tone.type) {
                tone.type = wf;
            }
            return wf;
        });
        setTones((tns) => {
            stopAudioOutput(tns);
            setIsPlaying(false);
            if (!tns.find((t) => t.hertz === tone.hertz && t.type === tone.type)) {
                return [...tns, tone];
            } else {
                return [...tns];
            }
        });
    }, [setTones, setIsPlaying, setWaveForm]);

    const onRemoveTone = useCallback((tone: ToneInterface) => {
        setTones((tns) => {
            stopAudioOutput(tns);
            setIsPlaying(false);
            const index = tns.findIndex((t) => t.hertz === tone.hertz && t.type === tone.type);
            if (index > -1) {
                tns.splice(index, 1);
            }
            return [...tns];
        });
    }, [setTones, setIsPlaying]);

    const onClearTones = useCallback(() => {
        setTones((tns) => {
            stopAudioOutput(tns);
            setIsPlaying(false);
            tns.splice(0, tns.length)
            return [...tns];
        });
    }, [setTones, setIsPlaying])

    const onCatalogToggle = useCallback(() => {
        setCatalogOpen(!isCatalogOpen);
    }, [isCatalogOpen, setCatalogOpen]);

    const onPlayPauseClick = useCallback(() => {
        if (isPlaying) {
            // stop
            stopAudioOutput(tones);
        } else {
            // start
            startAudioOutput(ctx, dest, tones);
        }
        setIsPlaying(!isPlaying);
    }, [isPlaying, setIsPlaying, tones]);

    return (
        <div className="wrapper">
            <Header onPlayPauseClick={onPlayPauseClick} isPlaying={isPlaying} />
            <CustomInputForm onAddTone={onAddTone} />
            <main data-catalog={isCatalogOpen ? "open" : "closed"}>
                <Catalog
                    isCatalogOpen={isCatalogOpen}
                    onCatalogToggle={onCatalogToggle}
                    onAddTone={onAddTone}
                    onWaveFormChange={onWaveFormChange} />
                <ToneList tones={tones} onRemoveTone={onRemoveTone} onClearTones={onClearTones} />
            </main>
        </div>
    );
}

export default App;

export function ucFirst(str: string): string {
    if (str && str.length > 1) {
        return `${str[0].toUpperCase()}${str.slice(1)}`;
    } else {
        return `${str || ''}`.toUpperCase();
    }
}

export function reverseMap<T>(arr: T[], callback: (item: T, index: number) => any): any[] {
    const results: any[] = [];
    for (let i = arr.length - 1; i >= 0; i--) {
        results.push(callback(arr[i], i));
    }
    return results;
}
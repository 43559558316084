import React, { useState, useCallback } from 'react';
import './Catalog.css';
import { Sections, NotesSection, SimpleSection } from '../Notes';
import CatalogSection from '../CatalogSection';
import CatalogNotes from '../CatalogNotes';
import { ToneInterface, ToneTypes } from '../ToneData';

type Props = {
    isCatalogOpen: boolean;
    onCatalogToggle: () => void;
    onAddTone: (tone: ToneInterface) => void;
    onWaveFormChange: (type: OscillatorType) => void;
};

const DEFAULT_TYPE = ToneTypes[0];


function Catalog(props: Props) {
    const { isCatalogOpen, onCatalogToggle, onAddTone, onWaveFormChange } = props;

    const [tab, setTab] = useState(Sections[0].key);
    const [type, setType] = useState<OscillatorType>(DEFAULT_TYPE);

    const onTabClick = useCallback((tabName: string) => {
        if (tabName !== tab) {
            setTab(tabName);
        }
    }, [tab, setTab]);

    const onTypeChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
        let value = event.target.value as OscillatorType;
        if (!ToneTypes.includes(value)) {
            value = ToneTypes[0];
        }
        console.log('catalog change', type, '=>', value)
        setType(value);
        onWaveFormChange(value);
    }, [type, setType, onWaveFormChange])

    return (
        <aside className="catalog" data-state={isCatalogOpen ? "open" : "closed"} data-tab={tab}>
            <button
                className="catalog-toggle"
                title={isCatalogOpen ? "Close" : "Open Catalog"}
                onClick={onCatalogToggle}>
                {isCatalogOpen ? '‹‹' : '››'}
            </button>
            <div className="catalog-type-selector">
                <label htmlFor='catalog_type'>Default Waveform</label>
                <select id="catalog_type" name="catalog_type" value={type} onChange={onTypeChange}>
                    {ToneTypes.map((toneType) => (
                        <option key={toneType} value={toneType}>{toneType}</option>
                    ))}
                </select>
            </div>
            <nav className="catalog-nav">
                <ul>
                    {Sections.map((itm) => (
                        <li
                            className={tab === itm.key ? 'selected' : ''}
                            key={itm.key}
                            onClick={() => onTabClick(itm.key)}>
                            {itm.name}
                        </li>
                    ))}
                </ul>
            </nav>
            <div className="catalog-content">
                {Sections.map((sect) => {
                    if (sect.key === 'notes') {
                        return (
                            <CatalogNotes
                                key={sect.key}
                                section={sect as NotesSection}
                                visible={sect.key === tab}
                                onAddTone={onAddTone} />)
                    } else {
                        return (
                            <CatalogSection
                                key={sect.key}
                                section={sect as SimpleSection}
                                visible={sect.key === tab}
                                onAddTone={onAddTone} />)
                    }
                })}
            </div>
        </aside>
    );
}

export default Catalog;

import React, { useState, useCallback } from 'react';
import './CatalogNotes.css';
import { NotesSection, Notes, SimpleSection } from '../Notes';
import { ToneInterface } from '../ToneData';
import CatalogSection from '../CatalogSection';

type Props = {
    section: NotesSection;
    visible: boolean;
    onAddTone: (tone: ToneInterface) => void;
};

const Octaves = Object.keys(Notes).map((v) => parseInt(v.replace(/[^\d]/g, ''), 10));
const DEFAULT_OCT = Octaves[Math.floor(Octaves.length / 2)];
const MIN_OCT = Math.min(...Octaves);
const MAX_OCT = Math.max(...Octaves);


function CatalogNotes(props: Props) {
    const { section, visible, onAddTone } = props;
    const [octave, setOctave] = useState<number>(DEFAULT_OCT);


    const onOctaveChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (!Octaves.find((v) => +v === +value)) {
            setOctave(DEFAULT_OCT);
        } else {
            setOctave(+value);
        }
    }, [setOctave]);

    const onOctaveDec = useCallback(() => {
        setOctave((oct) => {
            return Math.max(MIN_OCT, Math.min(oct - 1, MAX_OCT));
        });
    }, [setOctave]);

    const onOctaveInc = useCallback(() => {
        setOctave((oct) => {
            return Math.max(MIN_OCT, Math.min(oct + 1, MAX_OCT));
        });
    }, [setOctave]);

    return (
        <div className="catalog-notes" data-content={section.key} data-visible={visible}>
            <div className="catalog-notes-octave">
                <label htmlFor='octave_range' className="">
                    <button onClick={onOctaveDec} disabled={octave === MIN_OCT}>−</button>
                    <span>Octave <strong>{octave}</strong></span>
                    <button onClick={onOctaveInc} disabled={octave === MAX_OCT}>+</button>
                </label>
                <input type="range" id="octave_range" name="octave_range" value={octave} onChange={onOctaveChange} min={MIN_OCT} max={MAX_OCT} step="1" />
            </div>
            <div className="catalog-notes-content">
                {Octaves.map((oct) => (
                    <CatalogSection
                        key={oct}
                        section={{
                            name: `${section.name}_${oct}`,
                            key: `${section.key}_${oct}`,
                            data: Notes[oct],
                        } as SimpleSection}
                        visible={+oct === octave}
                        onAddTone={onAddTone} />
                ))}
            </div>
        </div>
    );
}

export default CatalogNotes;

import { ToneInterface, ToneTypes } from "./ToneData";

export function startAudioOutput(ctx: AudioContext, dest: AudioDestinationNode, tones: ToneInterface[]): void {
    if (ctx) {
        tones.forEach((tone) => {
            const osc = ctx.createOscillator();
            osc.type = tone.type || ToneTypes[0];
            osc.frequency.setValueAtTime(tone.hertz, ctx.currentTime);
            osc.connect(dest);
            osc.start();
            tone.oscillator = osc;
        })
    }
}

export function stopAudioOutput(tones: ToneInterface[]): void {
    try {
        tones.forEach((tone) => {
            if (tone.oscillator) {
                tone.oscillator.stop();
            }
        });
    } catch (err) {
        console.log('Error stopping audio', err);
    }
}
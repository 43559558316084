import React, { useCallback } from 'react';
import './CatalogFrequency.css';
import { ToneInterface } from '../ToneData';

type Props = {
    name: string;
    hertz: number;
    onAddTone: (tone: ToneInterface) => void;
};

function CatalogFrequency(props: Props) {
    const { name, hertz, onAddTone } = props;

    const addTone = useCallback(() => {
        console.log('click', name, hertz, onAddTone);
        if (!Number.isNaN(parseFloat(`${hertz}`))) {
            onAddTone({
                name: `${name}  (${hertz})`,
                hertz: parseFloat(`${hertz}`),
            });
        }
    }, [onAddTone, hertz, name]);

    return (
        <div className="catalog-frequency">
            <p>
                <strong>{name}</strong>
                <span>(<strong>{hertz}</strong>Hz)</span>
            </p>
            <button onClick={addTone}>Add</button>
        </div>
    );
}

export default CatalogFrequency;

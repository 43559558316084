import React, { useState, useCallback } from 'react';
import './ToneList.css';
import Tone from '../Tone';
import { ToneInterface } from '../ToneData';
import { reverseMap } from '../utils';

type Props = {
    tones: ToneInterface[];
    onRemoveTone: (tone: ToneInterface) => void;
    onClearTones: () => void;
};

const RESET_DELAY = 3000;
let timer: NodeJS.Timer | void;

function ToneList(props: Props) {
    const [confirm, setConfirm] = useState(false);

    const { tones, onRemoveTone, onClearTones } = props;

    const confirmDelay = useCallback(() => {
        timer = setTimeout(() => {
            setConfirm(false);
        }, RESET_DELAY);
    }, [setConfirm]);

    const onClearClick = useCallback(() => {
        if (confirm) {
            if (timer) {
                clearTimeout(timer);
            }
            onClearTones();
            setConfirm(false);
        } else {
            setConfirm(true);
            confirmDelay()

        }
    }, [onClearTones, confirm, setConfirm, confirmDelay]);
    return (
        <section className="tone-list">
            <h2>Tones
                <span>{tones.length ? (
                    <button
                        className={`clear-tones ${confirm ? 'confirm' : ''}`}
                        onClick={onClearClick}>
                        {confirm ? 'Confirm Clear' : 'Clear Tones'}
                    </button>
                ) : null}</span>
            </h2>
            <div className="tones-listing">
                {reverseMap<ToneInterface>(tones, (tone) => {
                    return (<Tone key={tone.hertz} tone={tone} onRemoveTone={onRemoveTone} />);
                })}
            </div>
        </section>
    );
}

export default ToneList;

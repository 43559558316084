import React from 'react';
import PlayPauseButton from '../PlayPauseButton';
import './Header.css';

type Props = {
    onPlayPauseClick: () => void;
    isPlaying: boolean;
};

function Header(props: Props) {
    const { onPlayPauseClick, isPlaying } = props;
    return (
        <header className="header">
            <h1>ToneGenerator</h1>
            <PlayPauseButton onPlayPauseClick={onPlayPauseClick} isPlaying={isPlaying} />
        </header>
    );
}

export default Header;

import React, { useCallback, useState } from 'react';
import './Tone.css';
import { ToneInterface } from '../ToneData';

type Props = {
    tone: ToneInterface
    onRemoveTone: (tone: ToneInterface) => void;
};

const RESET_DELAY = 3000;
let timer: NodeJS.Timer | void;

function Tone(props: Props) {
    const [confirm, setConfirm] = useState(false);
    const { tone, onRemoveTone } = props;

    const confirmDelay = useCallback(() => {
        timer = setTimeout(() => {
            setConfirm(false);
        }, RESET_DELAY);
    }, [setConfirm]);

    const onRemoveClick = useCallback(() => {
        if (confirm) {
            if (timer) {
                clearTimeout(timer);
            }
            onRemoveTone(tone);
            setConfirm(false);
        } else {
            setConfirm(true);
            confirmDelay()

        }
    }, [onRemoveTone, confirm, setConfirm, confirmDelay]);

    return (
        <div className="tone">
            <p>
                <strong>{tone.name}</strong>
                <span>({tone.hertz}Hz)</span>
                <em>{`<${tone.type}>`}</em>
            </p>
            <button
                className={`tone-remove ${confirm ? 'confirm' : ''}`}
                onClick={onRemoveClick}
                title={confirm ? 'Confirm' : 'Click to Remove'}>Ⓧ</button>
        </div>
    );
}

export default Tone;

import React, { useCallback, useState } from 'react';
import { ToneInterface, ToneTypes } from '../ToneData';
import { ucFirst } from '../utils';
import './CustomInputForm.css';

const DEFAULT_HERTZ = 432;
const DEFAULT_TYPE = ToneTypes[0];

type Props = {
    onAddTone: (tone: ToneInterface) => void;
};

function CustomInputForm(props: Props) {
    const [hertz, setHertz] = useState<number>(DEFAULT_HERTZ);
    const [type, setType] = useState<OscillatorType>(DEFAULT_TYPE);
    const { onAddTone } = props;

    const addTone = useCallback(() => {
        if (!Number.isNaN(parseFloat(`${hertz}`))) {
            onAddTone({
                name: `Custom ${ucFirst(type)} ${hertz}`,
                hertz: parseFloat(`${hertz}`),
                type: type,
            })
        }
    }, [hertz, onAddTone, type]);

    const onHertzChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (!Number.isNaN(parseFloat(value))) {
            setHertz(parseFloat(value));
        } else {
            setHertz(DEFAULT_HERTZ);
        }
    }, [setHertz])

    const onTypeChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
        let value = event.target.value as OscillatorType;
        if (!ToneTypes.includes(value)) {
            value = ToneTypes[0];
        }
        setType(value);
    }, [setType])

    const onHertzKeyPress = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            addTone();
        }
    }, [addTone])

    return (
        <section className="custom-input-form">
            <label htmlFor='custom_input'>Frequency (Hz)</label>
            <input
                type="number"
                id="custom_input"
                value={hertz}
                onChange={onHertzChange}
                onKeyUp={onHertzKeyPress}
                min="0"
                max="20000"
                placeholder="Hz" />
            <select id="custom_input_type" name="custom_input_type" value={type} onChange={onTypeChange}>
                {ToneTypes.map((toneType) => (
                    <option key={toneType} value={toneType}>{toneType}</option>
                ))}
            </select>
            <button onClick={addTone}>Add</button>
        </section>
    );
}

export default CustomInputForm;

export const ToneTypes: OscillatorType[] = [
    'sine',
    'square',
    'sawtooth',
    'triangle',
];

export interface ToneInterface {
    hertz: number;
    name: string;
    type?: OscillatorType;
    oscillator?: OscillatorNode;
}

export function toneEqual(t1: ToneInterface, t2: ToneInterface): boolean {
    return (t1.hertz === t2.hertz && t1.type === t2.type);
}